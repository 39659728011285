import React from "react"
import {Link} from "gatsby"
import GoodfinanceLogo from "./goodfinanceLogo"


export default () => (
  <section class="footerContainer">
    <div class="contentFooter">
        <div class="inner">
            <h3>お問い合わせ</h3>
            <div className="footerSList">
              <p class="footerNumber"><img src="/images/call.svg" alt="call" />06-6151-5004</p>
              <p class="footerMail"><img src="/images/mail.svg" alt="email" />info@good-finance.jp</p>
              <div class="footerTime">営業時間 平日 10:00 - 17:30</div>
            </div>
            <div class="footerButton"><Link to={`/contact/`}>フォームからお問い合わせ</Link></div>
            <GoodfinanceLogo />
        </div>
    </div>
  </section>
)