import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FooterContainer from "../components/footercontainer"
import { Link } from "gatsby"




export default () => (
    <Layout>
        <SEO />
        <main id="serviceMain">
        <div id="serviceContainer">
            <div className="serviceContent">
                <section className="serviceContentBox">
                    <div className="serviceHero">
                        <div className="inner">
                            <h1>
                                事業を行う上で、<br />
                                資金調達は最も重要な戦略です。
                            </h1>
                            <div className="serviceList">
                                <ul>
                                    <li>・借金はしないと起業時に決めている。</li>
                                    <li>・資金が足りなくなったらしようと思う。</li>
                                    <li>・今はあるからまだ大丈夫かな・・</li>
                                </ul>
                            </div>
                            <div className="serviceSS">
                                <div className="inner">
                                    <p>
                                        融資の場合、金融機関がお金を貸すためには審査があります。あなたが金融機関でしたら、資金が足りなくなってから来る人や業績が落ち込んできた人に貸したいと思うでしょうか？
                                    </p>
                                </div>
                            </div>
                                <div className="serviceButton"><Link to={`/contact/`}>今すぐ問い合わせる</Link></div>
                        </div>
                        <div className="serviceHeoroImg">
                            <img src="/images/servicehero.svg" alt="" />
                        </div>
                    </div>
                </section>
                <section className="servicePointSS">
                    <div className="inner">
                        <p>
                            必要な資金を必要な時期に借りるというのはとても大事なことです。<br />
                            創業というタイミングではその時期や金額を見積もるのは難しいです。<br />
                            そのような時は専門家に相談しましょう。
                        </p>
                    </div>
                </section>
                <section className="servicePoint">
                    <div className="sectionPointImg">
                        <img src="/images/sideBarImg.svg" alt="" />
                    </div>
                    <div className="servicePointText">
                        <h2>専門家を選ぶときのポイント</h2>
                        <ul>
                            <li>国から選ばれた認定支援機関であるか</li>
                            <li>実績があるか</li>
                            <li>面談対策があるか</li>
                            <li>顧問契約の条件がついているか</li>
                        </ul>
                    </div>
                </section>
                <section className="shikinTyoutatsu">
                    <div className="shikinTyoutastuImg">
                        <img src="/images/four.svg" alt="" />
                    </div>
                    <h2>種類の資金調達</h2>
                </section>
                <section className="shikinTyoutatsuList">
                    <div className="yushi shikinTyoutatsuListItem">
                        <h3>融資</h3>
                        <p>日本政策金融公庫や信用金庫など地域と密着した金融</p>
                        <p>機関などから資金を調達する方法です。</p>    
                        <p>日本政策金融公庫は創業支援に力を入れており、地域</p>    
                        <p>に密着した信用金庫も中小企業に協力的な金融機関</p>    
                        <p>です。日頃の運転資金から設備の購入資金まで取り扱</p>    
                        <p>っています。</p>
                        <div className="LIButton">
                            <Link to={`/yushi/`}>
                                もっと詳しく
                            </Link>  
                        </div>
                    </div>
                    <div className="hojyo shikinTyoutatsuListItem">
                        <h3>補助金</h3>
                        <p>設備などの購入を検討し、その有用性を事業計画とし</p>
                        <p>て申請し、採択されれは資金の補填をしてくれる制度<br /></p>
                        <p>です。様々な補助金がありますが、補助金ごとに年に</p>
                        <p>１回～３回ほど募集をしています。</p>
                        <p className="blankP"></p>
                        <p className="blankP"></p>
                        <div className="LIButton">
                            <Link to={`/hojokin/`}>
                                もっと詳しく
                            </Link>  
                        </div>
                    </div>
                    <div className="cloud shikinTyoutatsuListItem">
                        <h3>クラウドファンディング</h3>
                        <p>「インターネットを介して不特定多数の人々から少額</p>
                        <p>ずつ資金を調達する」ことを言います。</p>
                        <p>CAMPFIREというサイトなどでプロジェクトを発足</p>
                        <p>し、資金の提供を求めることができます。目標額を達</p>
                        <p>成するかなど要件もありますが、通常の販売とは異な</p>
                        <p>り、商品の販売前に支援を得ることができます。</p>
                        <div className="LIButton">
                            <Link to={`/cloud-funding/`}>
                                もっと詳しく
                            </Link>  
                        </div>
                    </div>
                    <div className="equity shikinTyoutatsuListItem">
                        <h3>エクイティーファイナンス</h3>
                        <p>企業が新株を発行して、事業のために資金を調達する<br /></p>
                        <p>ことを意味します。こちらは融資と違い、返済期限が</p>
                        <p>ない資金調達となります。</p>
                        <p>株主にとっては会社への支配権が薄まります。</p>
                        <p>手元資金で起業した会社などがビジネスを飛躍させる</p>
                        <p>ための資金調達の方法として行ったりします。</p>
                        <div className="LIButton">
                            <Link to={`/equity-finance/`}>
                                もっと詳しく
                            </Link>  
                        </div>
                    </div>
                </section>
                <section className="servicePr">
                    <div className="inner">
                        <h2>資金調達にお困りの経営者様</h2>
                        <div className="servicePrList">
                            <div className="servicePrListItem">
                                <h3>創業前からの準備</h3>
                                <ul className="sogyomaeList">
                                    <li>経験がない</li>
                                    <li>自己資金がない</li>
                                    <li>信用情報に自信がない</li>
                                </ul>
                            </div>
                            <div className="servicePrListItem">
                                <h3>創業後の資金調達</h3>
                                <ul>
                                    <li>決算書など過去の実績が大切</li>
                                    <li>金融機関との取引歴が一番の信用</li>
                                    <li>会社と個人の財布が一緒になっていないか</li>
                                </ul>
                            </div>
                        </div>
                        <div className="servicePrComment">
                            <div className="commentImg">
                                <img src="/images/servicePrHuman.svg" alt="" />
                            </div>
                            <div className="commentText">
                                <p>
                                    資金調達について、融資、補助金<br />
                                    その他なんでもご相談ください。
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="soudan">
                    <h3>相談の流れ</h3>
                    <div className="soudanNagare">
                        <img src="/images/taimen.svg" alt="" />
                        <img src="/images/hitaimen.svg" alt="" />
                        <p>対面</p>
                        <p>非対面</p>
                    </div>
                    <img className="soudanLine" src="/images/nagareLine.svg" alt="" />
                    <div className="soudanList">
                        <p>ヒアリング・お見積り</p>
                        <p>資料作成</p>
                        <p>面談</p>
                        <p>審査結果通知</p>
                        <p>融資実行</p>
                        <div className="soudanButton"><Link to={`/contact/`}>今すぐ問い合わせる</Link></div>
                    </div>
                </section>
            <FooterContainer />
            </div>
        </div> 

    </main>
    </Layout>
)